<template>
  <div>
    <h4>Please open other pages to visit this site</h4>
  </div>
</template>

<script>
export default {
  props: {},
};
</script>
<style scoped></style>
