import Vue from "vue";
import VueRouter from "vue-router";
import HelloWorld from "@/components/HelloWorld.vue";
import AndroidApp from "@/views/DownloadManage/AndroidApp.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: HelloWorld,
  },
  {
    path: "/download/android",
    name: "download",
    component: AndroidApp,
  },
];

const router = new VueRouter({
  mode: "history", // 使用历史模式
  routes,
});
// 全局导航守卫
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    console.warn(`未匹配的路由: ${to.fullPath}`, from); // 打印当前路径
    next("/"); // 重定向到首页
  } else {
    next();
  }
});

export default router;
